// ######################################################################
//                              IMPORT
// ######################################################################
import axios from 'axios';
import Cookies from 'js-cookie';
export { logout } from '../store/network-service';
import { logout } from '../store/network-service';
export * from './scripts';
export * from './tokens';
import { getStringDateFormat } from '../tools/index';
import { makeCancelable } from '../store/utils';

// ######################################################################
//                              sync
// ######################################################################

export const UPDATE_INIT = 'UPDATE_INIT';
export const updateInit = (settings) => ({
    type: UPDATE_INIT,
    payload: settings,
});

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const updateAuth = (auth) => ({
    type: UPDATE_AUTH,
    auth,
});

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = (settings) => ({
    type: UPDATE_SETTINGS,
    settings,
});

export const UPDATE_USER_CONFIG = 'UPDATE_USER_CONFIG';
export const updateConfig = (config) => ({
    type: UPDATE_USER_CONFIG,
    config,
});

export const ADD_TOAST = 'ADD_TOAST';
export const addToast = (data) => ({
    type: ADD_TOAST,
    data,
});

export const REMOVE_TOAST = 'REMOVE_TOAST';
export const removeToast = (id) => ({
    type: REMOVE_TOAST,
    id,
});

export const RENAME_WORKSPACES = 'RENAME_WORKSPACES';

// ######################################################################
//                              authentication
// ######################################################################

export const SIGNIN_USER = 'SIGNIN_USER';
export const signin_user = () => ({ type: SIGNIN_USER });

export const SIGNIN_USER_RESET = 'SIGNIN_USER_RESET';
export const signin_reset = () => ({ type: SIGNIN_USER_RESET });
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const signin_success = (user) => ({ type: SIGNIN_USER_SUCCESS, payload: user });
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const signin_failed = (error) => ({ type: SIGNIN_USER_FAILURE, payload: error });

export const authenticate = (login, password) => {
    return (dispatch /*, getState*/) => {
        const data = { login, password };

        const p = new Promise((resolve, reject) => {
            axios
                .create()
                .post(`/login`, data)
                .then(function (response) {
                    if (response.data.data.settings) {
                        dispatch(updateSettings(response.data.data.settings));
                    }
                    if (response.data.data.config) {
                        dispatch(updateConfig(response.data.data.config));
                    }

                    dispatch(signin_success(response.data.data));
                    dispatch(get_devices_metadata());
                    resolve(null);
                })
                .catch(function (error) {
                    if (!error.response) {
                        dispatch(
                            signin_failed({
                                header: 'Error',
                                message: error.message,
                            }),
                        );
                    } else if (error.response.status === 423) {
                        const { data } = error.response.data;
                        return resolve(data);
                    }
                    reject(error);
                });
        });
        return p;
    };
};

export const task = (workspaceName = 'default_default', taskId) => {
    const config = {
        params: {},
    };
    return (dispatch, getState) => {
        return makeCancelable(
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/queue/${encodeURIComponent(taskId)}`, config)
                .then(({ data: { status } }) => {
                    return status;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

export const getMe = () => {
    return (dispatch /*, getState*/) => {
        const cookie = Cookies.get('ast-session');
        if (cookie) {
            localStorage.setItem('x-token', cookie);
            Cookies.remove('ast-session');
        }
        const sessionId = localStorage.getItem('x-token');
        const config = {
            params: {},
            headers: {
                Authorization: 'Bearer ' + sessionId,
            },
        };
        dispatch(signin_user());
        return axios
            .get(`/me`, config)
            .then(function (response) {
                if (response.data.data.settings) {
                    dispatch(updateSettings(response.data.data.settings));
                }
                dispatch(signin_success({ ...response.data.data, token: sessionId }));
                if (!sessionId?.startsWith('vtm:')) {
                    dispatch(get_devices_metadata());
                }
                return true;
            })
            .catch(function (error) {
                if (!error.response) {
                    dispatch(
                        signin_failed({
                            header: 'Error',
                            message: error.message,
                        }),
                    );
                } else {
                    dispatch(
                        signin_failed({
                            header: 'Authentication failed',
                            message: error.response.statusText,
                        }),
                    );
                }
                return false;
            });
    };
};

export const updateInfo = (settings) => {
    return (dispatch /*, getState*/) => {
        dispatch(updateSettings(settings));
        const content = {
            settings: {
                night_mode: settings.night_mode,
                spotlight_mode: settings.spotlight_mode,
                show_section_lines: settings.show_section_lines,
                sidebar_small: settings.sidebar_small,
                sidebar_static: settings.sidebar_static,
                sidebar_dark: settings.sidebar_dark,
                nav_dark: settings.nav_dark,
            },
        };

        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/settings`, content, config)
                .then((response) => {
                    const { data } = response.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const signOut = () => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        return axios
            .post(`/logout`, {}, config)
            .then(function (response) {
                dispatch(logout());
            })
            .catch(function (error) {
                dispatch(logout());
            });
    };
};

// ######################################################################
//                              password
// ######################################################################

export const riddle = (login) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: { login },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .create()
                .get(`/riddle`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const changepassword = (newPassword, oldPassword, token) => {
    return (dispatch, getState) => {
        const sessionId = localStorage.getItem('x-token') || token;
        // const state = getState();
        const config = {
            params: {},
            headers: {
                Authorization: 'Bearer ' + sessionId,
            },
        };

        const data = { newPassword, oldPassword };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/changepassword`, data, config)
                .then((response) => {
                    token && dispatch(signin_success({ ...response.data.data, token }));
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const resetpassword = (login, email) => {
    return (dispatch /*, getState*/) => {
        const data = { login, email };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/resetpassword`, data)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              company
// ######################################################################
export const get_companies = () => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/company`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const post_company = (company) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/company`, company, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const update_company = (company) => {
    const config = {
        params: {},
    };
    const { id, ...payload } = company;
    return (dispatch /*, getState*/) => {
        return makeCancelable(
            axios
                .put(`/api/1.0/company/${encodeURIComponent(id)}`, payload, config)
                .then(({ data }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

export const delete_company = (company_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/company/${encodeURIComponent(company_id)}`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};
// ######################################################################
//                              users
// ######################################################################
export const get_users = (company_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: { company_id },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/user`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const reset_user_password = (user_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/user/${encodeURIComponent(user_id)}/resetpassword`, null, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const put_user = (user) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/user/${encodeURIComponent(user.id)}`, user, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const post_user = (user) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/user`, user, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_user = (user_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/user/${encodeURIComponent(user_id)}`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};
// ######################################################################
//                              workspaces
// ######################################################################

export const UPDATE_WORKSPACES = 'UPDATE_WORKSPACES';
export const flushWorkspaces = () => ({
    type: UPDATE_WORKSPACES,
    loading: false,
    error: '',
    workspaces: [],
});

// récupération des lignes
export const get_workspaces = () => {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_WORKSPACES, loading: true, error: '', workspaces: [] });
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces`, config)
                .then((response) => {
                    dispatch({ type: UPDATE_WORKSPACES, loading: false, workspaces: response.data.status == 'ok' ? response.data.data : [] });
                    resolve(response.data?.data ?? []);
                })
                .catch((error) => {
                    dispatch({ type: UPDATE_WORKSPACES, loading: false, error, workspaces: [] });
                    reject(error);
                });
        });
        return p;
    };
};

// create a workspace
export const post_workspace = (workspace_name, company_id, language_name = 'auto') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/workspaces`, { workspace_name, company_id, language_name }, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// update language a workspace
export const put_languageWorkspace = (workspace_name, language_name = 'auto') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspace_name)}/language/${encodeURIComponent(language_name)}`, {}, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_workspace = (workspaceName) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// update custom configuratio of a workspace
export const put_configurationWorkspace = (workspace_name, filterConfiguration, configuration) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(
                    `/api/1.0/workspaces/${encodeURIComponent(workspace_name)}/configuration/${encodeURIComponent(filterConfiguration)}`,
                    configuration,
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// get custom configuratio of a workspace
export const get_configurationWorkspace = (workspace, filterConfiguration) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/workspaces/${encodeURIComponent(workspace)}/configuration/${encodeURIComponent(filterConfiguration)}`).then(({ data }) => {
            if (data.status == 'ok') return data;
            return null;
        }),
    );

export const delete_configurationWorkspace = (workspaceName, filterConfiguration) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/configuration/${encodeURIComponent(filterConfiguration)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// update label of a workspace
export const put_labelWorspace = (workspace_name, newLabel) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspace_name)}/newlabel/${encodeURIComponent(newLabel)}`, {}, config)
                .then((response) => {
                    dispatch({ type: RENAME_WORKSPACES, payload: { workspace_name, label: newLabel } });
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// get stats of the workspace
export const get_workspaceStats = (workspace) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/workspaces/${encodeURIComponent(workspace)}/stats`).then(({ data }) => {
            if (data.status == 'ok') return data;
            return null;
        }),
    );

// ######################################################################
//                              vthings
// ######################################################################
export const UPDATE_VTHINGS = 'UPDATE_VTHINGS';

// récupération des vthings
export const get_vthings = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_VTHINGS, payload: { loading: true, error: '', vthings: [] } });
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthings`, config)
                .then((response) => {
                    dispatch({ type: UPDATE_VTHINGS, payload: { loading: false, error: '', vthings: response.data.status == 'ok' ? response.data.data : [] } });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({ type: UPDATE_VTHINGS, payload: { loading: false, error, vthings: [] } });
                    reject(error);
                });
        });
        return p;
    };
};

// get webhooks from vthing
export const get_webhooks = (workspaceName = 'default', vthing_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/webhooks`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_store = () => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/store`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_logo = (logoName) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/store/logos/${encodeURIComponent(logoName)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const get_devices_metadata = () => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        // const p = new Promise((resolve, reject) => {
        return axios
            .get(`/api/1.0/store/devices`, config)
            .then(({ data, status }) => {
                if (status == 200 && data && data.data) {
                    dispatch({ type: UPDATE_DEVICES, settings: data.data });
                }
                // resolve(data, status);
            })
            .catch((error) => {
                //  reject(error);
            });
        // });
        // return p;
    };
};

export const post_vthing = (content, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing`, content, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_vthing = (vthing_id, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const put_vthing = (vthing_id, content, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}`, content, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              Groups
// ######################################################################
export const get_groups = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/groups`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const post_group = (content, group_name, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/group/${encodeURIComponent(group_name)}`, content, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_group = (group_name, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/group/${encodeURIComponent(group_name)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              devices
// ######################################################################

export const change_device_name = (device_name, new_device_name, workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .put(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/device/${encodeURIComponent(device_name)}/rename/${encodeURIComponent(
                        new_device_name,
                    )}`,
                    {},
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              custom user
// ######################################################################

export const get_custom_users = (workspaceName = 'default', vthing_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/users`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const edit_custom_user = (workspaceName = 'default', vthing_id, user) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .put(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/user/${encodeURIComponent(user.id)}`,
                    user,
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_custom_user = (workspaceName = 'default', vthing_id, user_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .delete(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/user/${encodeURIComponent(user_id)}`,
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              Messenger
// ######################################################################
export const MESSENGER_ADD = 'MESSENGER_ADD';
export const addMessage = (ctx, text, type) => {
    const date = new Date();
    const time = `${getStringDateFormat(date, false)}`;
    // eslint-disable-next-line no-unused-vars
    const { device, stream, streamPlaceHolder, streamStop, time: unused, utterance, ...others } = ctx;
    return {
        type: MESSENGER_ADD,
        payload: { device, message: { ...others, text, type, time }, stream },
    };
};

export const MESSENGER_FEEDBACK = 'MESSENGER_FEEDBACK';
export const messageFeedback = (device, id, like) => {
    return {
        type: MESSENGER_FEEDBACK,
        payload: { device, id, like },
    };
};

export const MESSENGER_ATTACHMENT_RM = 'MESSENGER_ATTACHMENT_RM';
export const rmMessageAttachment = (device) => {
    return {
        type: MESSENGER_ATTACHMENT_RM,
        payload: { device },
    };
};

// ######################################################################
//                              verbatim
// ######################################################################

// récupération des verbatims
export const get_verbatims = (workspaceName = 'default', start_date, end_date, status, status_response, limit, offset, others) => {
    return (dispatch, getState) => {
        const config = {
            params: { start_date, end_date, status, status_response, limit, offset, extract: false, ...others },
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/verbatim/${encodeURIComponent(workspaceName)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              dashboard
// ######################################################################

export const get_overview = () => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/overview`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_workspaceOverview = (workspaceName = 'default', days = 10, start_date = null, end_date = null) => {
    return (dispatch, getState) => {
        const config = {
            params: { days, start_date, end_date },
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/overview/${encodeURIComponent(workspaceName)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              custom view
// ######################################################################

export const get_form = (request_id) => {
    return () =>
        /*dispatch*/
        /*, getState*/ {
            const config = {
                params: {},
            };
            const p = new Promise((resolve, reject) => {
                axios
                    .create()
                    .get(`/api/1.0/signin/${encodeURIComponent(request_id)}/form`, config)
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            return p;
        };
};

// ######################################################################
//                              PIN codes
// ######################################################################

export const pin_code_authentication = (request_id, form) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
            headers: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .create()
                .post(`/api/1.0/signin/${encodeURIComponent(request_id)}/authenticate`, form, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_pin_codes = (workspaceName = 'default', vthing_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/codes`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const check_pin_code = (workspaceName = 'default', vthing_id, code) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .head(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${vthing_id}/code/${encodeURIComponent(code)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const create_pin_code = (workspaceName = 'default', vthing_id, pincode) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/code`, pincode, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const edit_pin_code = (workspaceName = 'default', vthing_id, pincode) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .put(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/code/${encodeURIComponent(pincode._id)}`,
                    pincode,
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_pin_code = (workspaceName = 'default', vthing_id, pincode_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };

        const p = new Promise((resolve, reject) => {
            axios
                .delete(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/vthing/${encodeURIComponent(vthing_id)}/code/${encodeURIComponent(pincode_id)}`,
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};
