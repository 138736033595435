import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const eSCOPES = {
    BACK_PROMPTS_READ: 'back.prompts:read',
    BACK_PROMPTS_WRITE: 'back.prompts:write',
    ORGANIZATION_DATABASE: 'org.db:write',
};

export const ACL_PERMISSION = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'edit',
    DELETE: 'delete',
    RESCTRICT: 'limited',
};

export const TYPE_PERMISSION = {
    NLP_RAG: 'nlp_rag',
    NLP_CLASS: 'nlp_class',
    NLP_CORPUS: 'nlp_corpus',
    NLP_INTENT: 'nlp_intent',
};

const ROLE_ACLS = {
    superAdmin: {
        [TYPE_PERMISSION.NLP_RAG]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CLASS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CORPUS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_INTENT]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
    },
    companyPartner: {
        [TYPE_PERMISSION.NLP_RAG]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CLASS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CORPUS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_INTENT]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
    },
    companyAdmin: {
        [TYPE_PERMISSION.NLP_RAG]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CLASS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_CORPUS]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
        [TYPE_PERMISSION.NLP_INTENT]: [ACL_PERMISSION.CREATE, ACL_PERMISSION.READ, ACL_PERMISSION.UPDATE, ACL_PERMISSION.DELETE],
    },
    companyUser: {
        [TYPE_PERMISSION.NLP_RAG]: [ACL_PERMISSION.READ],
        [TYPE_PERMISSION.NLP_CLASS]: [ACL_PERMISSION.READ],
        [TYPE_PERMISSION.NLP_CORPUS]: [ACL_PERMISSION.READ],
        [TYPE_PERMISSION.NLP_INTENT]: [ACL_PERMISSION.READ],
    },
};

// Cette fonction accepte un composant...
const withAcl = (WrappedComponent, type) => {
    class AclController extends Component {
        static propTypes = {
            auth: PropTypes.object.isRequired,
            // router-dom
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
        };

        constructor(props) {
            const query = new URLSearchParams(props.location.search);
            super(props);
            const ws = query.get('ws');
            const permissions = this.buildPermission(ws);
            this.state = { ws: query.get('ws'), permissions };
        }

        componentDidUpdate = (prevProps) => {
            const query = new URLSearchParams(this.props.location.search);
            if (this.state.ws != query.get('ws')) {
                const ws = query.get('ws');
                const permissions = this.buildPermission(ws);
                this.setState({ ws, permissions });
            }
        };

        buildPermission(workspace) {
            const { acls, role } = this.props.auth;
            const permissions = {
                [ACL_PERMISSION.CREATE]: false,
                [ACL_PERMISSION.READ]: false,
                [ACL_PERMISSION.UPDATE]: false,
                [ACL_PERMISSION.DELETE]: false,
                [ACL_PERMISSION.RESCTRICT]: false,
            };
            ROLE_ACLS[role]?.[type]?.forEach((p) => (permissions[p] = true));
            if (acls.hasOwnProperty(workspace)) {
                // acl is from specific workspace
                const ws_permission = acls[workspace];
                ws_permission[type]?.forEach((p) => (permissions[p] = true));
                if (typeof ws_permission.managed == 'boolean') {
                    permissions.limited = ws_permission.managed;
                }
            } else if (acls.hasOwnProperty('*')) {
                // acl is from all workspaces
                const ws_permission = acls['*'];
                ws_permission[type]?.forEach((p) => (permissions[p] = true));
                if (typeof ws_permission.managed == 'boolean') {
                    permissions.limited = ws_permission.managed;
                }
            }

            return permissions;
        }
        /*
        hasPermission = (type, permission) => {
            const { ws } = this.state;
            const { acls, role } = this.props.auth;
            let result = false;
            if (acls.hasOwnProperty(this.state.ws)) {
                const ws_permission = acls[ws];
                result = !!ws_permission[type]?.find((p) => p == permission);
            } else if (acls.hasOwnProperty('*')) {
                const ws_permission = acls['*'];
                result = !!ws_permission[type]?.find((p) => p == permission);
            }
            if (!result) {
                result = !!ROLE_ACLS[role]?.[type]?.find((p) => p == permission);
            }
            return false;
        };
        */
        render() {
            return <WrappedComponent {...this.props} acl={{ ws: this.state.ws, permissions: this.state.permissions }} />;
        }
    }
    return connect(
        ({ auth }) => ({
            auth,
        }),
        null,
    )(AclController);
};

export default withAcl;
