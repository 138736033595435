export const getStringDateFormat = (date, short = false) => {
    const d = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    const m = (date.getMonth() + 1) <= 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    if (short == true)
        return d + '/' + m + '/' + date.getFullYear(); 
    const h = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();
    const sec = date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds();
    return d + '/' + m + '/' + date.getFullYear() + ' ' + h + ':' + min + ':' + sec;
};

export const convertDate = (inputFormat) => {
    if (inputFormat == undefined) return '-';
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

export const convertDateTime = (inputFormat) => {
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }
    const d = new Date(inputFormat);
    return `${[pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')} ${[pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')}`;
};

export const asyncForEach = async (array, callback) => {
    let ret = true;
    for (let index = 0; ret && index < array.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        ret = await callback(array[index], index, array);
    }
};

export const normalize = (str) => {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

export const moveCaretAtEnd = (e) => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
};
