export const makeCancelable = (promise, source) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
        stop() {
            source?.cancel();
            hasCanceled_ = true;
        },
    };
};

export const debounceCancelable = (promise, options = { source: null, ttl: 1000 }) => {
    let timeout;
    let cancelPrevious;

    const debouncedFunction = (...args) => {
        clearTimeout(timeout);

        if (cancelPrevious) {
            cancelPrevious({ isCanceled: true });
        }

        return new Promise((resolve, reject) => {
            cancelPrevious = reject;
            timeout = setTimeout(async () => {
                try {
                    const result = await promise(...args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }, options.ttl);
        });
    };

    debouncedFunction.cancel = () => {
        clearTimeout(timeout);
        if (cancelPrevious) {
            cancelPrevious({ isCanceled: true });
        }
    };

    return debouncedFunction;
};
